import { javascript } from "@codemirror/lang-javascript"
import { vscodeDark } from "@uiw/codemirror-theme-vscode"
import CodeMirror from "@uiw/react-codemirror"
import { useEffect, useState } from "react"
import { runInRestrictedEnvironment } from "../../shared/utils/executor"

const CodeEditor = ({
  value,
  onChange,
  context = {},
}: {
  value: string
  onChange?: (v: string) => void
  context?: any
}) => {
  const [code, setCode] = useState(value)
  const [executionError, setExecutionError] = useState(false)

  const onChangeHandler = (value) => {
    setCode(value)
    onChange?.(value)
  }

  useEffect(() => {
    setCode(value)
  }, [value])

  useEffect(() => {
    if (code.length > 0) {
      const result = runInRestrictedEnvironment(code, {
        tasks: [],
        events: [],
        contracts: [],
        users: [],
        talents: [],
        ...context,
      })
      if (result === undefined) {
        setExecutionError(true)
      } else {
        setExecutionError(false)
      }
    }
  }, [code, context])

  const renderCodeStatus = () => {
    return (
      <div
        className={`absolute bottom-0 right-0 z-10 p-2 text-[8px] ${executionError ? "bg-red-300" : "bg-green-300"} rounded-tl-xl`}
      >
        <strong>code status:</strong>{" "}
        {executionError ? "Failed execution!" : "Successfully executed!"}
      </div>
    )
  }

  return (
    <div className="relative h-full w-full">
      <style>
        {`
                    .cm-focused {
                        outline: none !important;
                    }
                    .cm-editor {
                        height: 100%;
                    }
                `}
      </style>
      <CodeMirror
        style={{ fontSize: 12, zIndex: 1, height: "20rem" }}
        theme={vscodeDark}
        value={code}
        className="h-full min-h-[12rem] w-full"
        extensions={[javascript({ jsx: true })]}
        onChange={onChangeHandler}
      />
      {renderCodeStatus()}
    </div>
  )
}

export default CodeEditor
